import { FC, WebCellProps } from 'web-cell';
import { Ratio } from 'boot-cell';

export type AMapProps = WebCellProps & Record<'latitude' | 'longitude', number>;

export const createMapURL = ({ longitude, latitude, title }: AMapProps) =>
    '//m.amap.com/navi/?' +
    new URLSearchParams({
        dest: [longitude, latitude] + '',
        destName: title,
        key: '8325164e247e15eea68b59e89200988b'
    });

export const AMap: FC<AMapProps> = (props: AMapProps) => (
    <Ratio>
        <iframe src={createMapURL(props)} />
    </Ratio>
);

export const AMapLink: FC<AMapProps> = ({ children, className, ...props }) => {
    props.title ||= children + '';

    return (
        <address className={className}>
            <a target="_blank" href={createMapURL(props)}>
                {children}
            </a>
        </address>
    );
};
