import { FC, WebCellProps } from 'web-cell';
import { TextColor, BGIconProps, FAIcon, ButtonProps, Button } from 'boot-cell';
import classNames from 'classnames';

import * as style from './index.module.less';

export interface IconProps extends WebCellProps {
    name: string;
    color?: TextColor;
    size?: number;
}

export const Icon: FC<IconProps> = ({
    name,
    color = 'dark',
    className,
    size = 1,
    style,
    children,
    ...rest
}) => (
    <span
        className={classNames(
            'iconfont',
            `icon${name}`,
            color && `text-${color}`,
            className
        )}
        style={{ ...style, fontSize: `${size}rem` }}
        {...rest}
    />
);

export const BGIcon: FC<BGIconProps> = ({
    size,
    color,
    className,
    type,
    ...rest
}) => (
    <span
        className={classNames(
            'fa-stack',
            size && 'fa-' + (typeof size === 'number' ? size + 'x' : size),
            color && 'text-' + color,
            className
        )}
    >
        <FAIcon name={type} stack={2} />
        <Icon
            {...rest}
            className="position-relative"
            size={typeof size === 'number' ? size : 1}
            color="primary"
        />
    </span>
);

export type IconButtonProps = ButtonProps & IconProps;

export const IconButton: FC<IconButtonProps> = ({
    className,
    href,
    name,
    color,
    size,
    children,
    ...rest
}) => {
    const URI = href && new URL(href + '', self.location.href);

    return (
        <Button
            {...rest}
            className={classNames(style.box, className)}
            color="primary"
            href={href}
            onClick={event =>
                (event.currentTarget as HTMLAnchorElement).protocol ===
                    'data:' && event.preventDefault()
            }
        >
            <Icon name={name} color={color} size={size} />

            {!URI || /^http/.test(URI.protocol) ? null : (
                <span className={style.tooltip}>
                    {URI.protocol === 'data:' ? (
                        <img src={href} />
                    ) : (
                        (href + '').split(':').slice(1).join(':')
                    )}
                </span>
            )}
        </Button>
    );
};
