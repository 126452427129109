import { JsxChild, JsxChildren } from 'dom-renderer';
import { FC } from 'web-cell';
import { marked, Renderer } from 'marked';
import { SpinnerBox } from 'boot-cell';

import * as style from './index.module.less';
import { service } from '../../model';

export interface PostFrameProps {
    title: string;
    metaList: JsxChild[];
    header?: JsxChildren;
    footer?: JsxChildren;
    content: string;
}

const renderer = new Renderer();

renderer.image = ({ href, title, text }) =>
    `<img class="d-block m-auto mw-100" src=${new URL(
        href,
        service.baseURI
    )} title=${title || text} />`;

export const PostFrame: FC<PostFrameProps> = ({
    title,
    metaList,
    header,
    content,
    footer
}) => (
    <SpinnerBox
        className={`mt-5 mx-auto px-3 py-5 ${style.root}`}
        style={{ maxWidth: '75rem' }}
        cover={!content}
    >
        <h1 style={{ fontSize: '1.5rem' }}>{title}</h1>
        <ul className="list-inline text-muted" style={{ fontSize: 'small' }}>
            {metaList.map(item => (
                <li className="list-inline-item mr-4">{item}</li>
            ))}
        </ul>
        {header}
        <article
            className="mt-5"
            innerHTML={content && (marked(content, { renderer }) as string)}
        />
        {footer}
        <footer className="text-muted py-4">
            成都·二拾三度五所发布展示的“作品/文章”版权归成都·二拾三度五所有，任何商业用途均须联系作者。如未经授权用作他处，作者将保留追究侵权者法律责任的权利。
        </footer>
    </SpinnerBox>
);
