import { FC, WebCellProps } from 'web-cell';
import classNames from 'classnames';

import { Icon } from '../Icon';
import * as styles from './index.module.less';
import { service } from '../../model/Base';

export type BrandCardProps = WebCellProps<HTMLAnchorElement> &
    Record<'image' | 'description', string>;

export const BrandCard: FC<BrandCardProps> = ({
    className,
    style,
    image,
    target,
    href,
    title,
    description,
    children,
    ...rest
}) => (
    <li
        className={classNames(styles.card, className)}
        style={{
            ...style,
            backgroundImage: `url(${new URL(image, service.baseURI)})`
        }}
        {...rest}
    >
        <a {...{ target, href }}>
            <div>
                <h3>{title}</h3>
                <hr />
                {description}
            </div>
            <Icon name="arrow-right" size={2} color="white" />
        </a>
    </li>
);
