import { observable } from 'mobx';
import { BaseModel, toggle } from 'mobx-restful';

import { BaseData, File, service } from './Base';
import { Client } from './Case';

export interface User
    extends BaseData,
        Partial<Record<'title' | 'bio', string>> {
    username: string;
    avatar: File;
}

export interface Tag extends BaseData {
    name: string;
    model: 'Article' | 'Case' | 'Activity';
}

export interface Highlight
    extends BaseData,
        Record<'title' | 'summary', string> {
    photo: File;
}

export class MetaModel extends BaseModel {
    @observable
    accessor onTop = true;

    @observable
    accessor deviceType: 'phone' | 'pad' | 'desktop' = 'desktop';

    @observable
    accessor categories: Tag[] = [];

    @observable
    accessor tags: Tag[] = [];

    @observable
    accessor gallery: File[] = [];

    @observable
    accessor staffs: User[] = [];

    @observable
    accessor highlights: Highlight[] = [];

    @observable
    accessor partners: Client[] = [];

    constructor() {
        super();
        this.detectScroll();
        self.addEventListener('scroll', this.detectScroll);
        this.getDeviceType();
        self.addEventListener('resize', this.getDeviceType);

        this.loadTags();
    }

    @toggle('downloading')
    async loadTags() {
        const [{ body: categories }, { body: tags }] = await Promise.all([
            service.get<Tag[]>('/categories'),
            service.get<Tag[]>('/tags')
        ]);
        this.categories = categories;
        return (this.tags = tags);
    }

    detectScroll = () =>
        (this.onTop = document.scrollingElement.scrollTop === 0);

    getDeviceType = () => {
        const { innerWidth } = self;

        return (this.deviceType =
            innerWidth < 577 ? 'phone' : innerWidth < 769 ? 'pad' : 'desktop');
    };

    categoriesOf(model: Tag['model']) {
        return this.categories
            .map(item => item.model === model && item.name)
            .filter(Boolean);
    }

    tagsOf(model: Tag['model']) {
        return this.tags
            .map(item => item.model === model && item.name)
            .filter(Boolean);
    }

    @toggle('downloading')
    async getGallery() {
        const {
            body: { gallery }
        } = await service.get<{ gallery: File[] }>('/asset');

        return (this.gallery = gallery);
    }

    @toggle('downloading')
    async getStaffs() {
        const { body } = await service.get<User[]>('/users');

        return (this.staffs = body);
    }

    @toggle('downloading')
    async getHighlights() {
        const { body } = await service.get<Highlight[]>('/highlights');

        return (this.highlights = body);
    }

    @toggle('downloading')
    async getPartners() {
        const { body } = await service.get<Client[]>(
            '/clients?partner=true&_sort=updated_at:DESC'
        );
        return (this.partners = body);
    }
}
