.image {
    min-height: 10rem;
    background-size: contain;
}
:global {
    .column-carousel .carousel-indicators {
        margin-bottom: -1rem;
        align-items: center;
        & > li {
            width: 0.5rem;
            height: 0.5rem;
            border: none;
            border-radius: 50%;
            background: var(--secondary-color);
            &.active {
                background: var(--primary-color);
            }
        }
    }
}
