import { BaseArticle, Post, ArticleModel } from './Article';
import { service } from './Base';

export interface Registration
    extends Record<'name' | 'mobilePhone' | 'company' | 'position', string> {
    activity: Activity;
}

export interface Activity
    extends BaseArticle,
        Post,
        Record<'startTime' | 'endTime', Date>,
        Record<'address' | 'organizer', string> {
    city?: string;
    own: boolean;
    registrations: Registration[];
}

export class ActivityModel extends ArticleModel<Activity> {
    baseURI = '/activities';
    searchKeys = ['title', 'city', 'address'];
    rangeKeys = ['startTime', 'endTime'];

    getDayList(startTime: string) {
        const end = new Date(startTime);

        end.setDate(end.getDate() + 1);

        return super.getList(
            {
                startTime: [new Date(startTime), end],
                own: false
            },
            1
        );
    }

    register({ mobilePhone, ...data }: Registration) {
        return service.post('/registrations', {
            mobilePhone: mobilePhone + '',
            ...data
        });
    }
}
