import { JsxChildren } from 'dom-renderer';
import {
    WebCellProps,
    WebCell,
    component,
    attribute,
    observer
} from 'web-cell';
import { observable } from 'mobx';
import { Nav, NavLink } from 'boot-cell';

import * as style from './index.module.less';

export interface TabNavProps extends WebCellProps {
    header: JsxChildren;
    path: string;
    list: { title: string; name?: string }[];
    activeIndex?: number;
}

export interface TabNav extends WebCell<TabNavProps> {}

@observer
@component({
    tagName: 'tab-nav'
})
export class TabNav extends HTMLElement implements WebCell<TabNavProps> {
    @observable
    accessor header: JsxChildren;

    @attribute
    @observable
    accessor path = '';

    @observable
    accessor list = [];

    @attribute
    @observable
    accessor activeIndex = 0;

    connectedCallback() {
        this.classList.add(
            'py-5',
            'row',
            'align-items-center',
            'text-sm-center'
        );
    }

    render() {
        const { header, activeIndex, list, path } = this;

        return (
            <>
                <h2 className="col-12 col-sm-5 mb-5 mb-sm-0 pl-2 pl-sm-0">
                    {header}
                </h2>
                <Nav
                    itemMode="masthead"
                    className="col-12 col-sm-7 justify-content-around justify-content-sm-center"
                >
                    {list.map(({ title, name }, index) => (
                        <NavLink
                            className={`px-1 ${style.link}`}
                            href={path + (index ? name || title : '')}
                            active={index === activeIndex}
                            onClick={() => (this.activeIndex = index)}
                        >
                            {title}
                        </NavLink>
                    ))}
                </Nav>
            </>
        );
    }
}
