import { Filter } from 'mobx-restful';

import { BaseData, File, StrapiListModel } from './Base';
import { Tag, User } from './Meta';

export interface BaseArticle
    extends BaseData,
        Record<'title' | 'content', string> {
    banner?: File;
    category: Tag;
    tags: Tag[];
    editor: User;
}

export type Post = Partial<Record<'sourceName' | 'sourceURL', string>>;

export type Article = BaseArticle & Post;

export class ArticleModel<
    D extends Article = Article,
    F extends Filter<D> = Filter<D>
> extends StrapiListModel<D, F> {
    baseURI = '/articles';
    searchKeys = ['title'];
    relations = ['category', 'tags'];
    accessor pageSize = 12;
}
