import { FC, WebCellProps } from 'web-cell';
import { CarouselProps, Carousel, CarouselItem, Image } from 'boot-cell';
import classNames from 'classnames';

import * as style from './index.module.less';

interface Picture {
    title: string;
    image: string | URL;
}

export interface ColumnCarouselProps extends CarouselProps, WebCellProps {
    column: number;
    itemClass: string;
    list: Picture[];
}

export const ColumnCarousel: FC<ColumnCarouselProps> = ({
    column,
    list,
    className,
    itemClass,
    ...rest
}) => {
    const group = list.reduce((group, item, index) => {
            const last = group[group.length - 1];

            if (index % column) last.push(item);
            else group.push([item]);

            return group;
        }, [] as Picture[][]),
        width = `${Math.floor(100 / column) - 1}%`;

    return (
        <Carousel
            className={classNames('column-carousel', 'd-block', className)}
            interval={3}
            {...rest}
        >
            {group.map(list => (
                <CarouselItem>
                    <div className="d-flex justify-content-between">
                        {list.map(({ image, title }) => (
                            <Image
                                className={classNames(style.image, itemClass)}
                                style={{ width }}
                                background
                                src={image + ''}
                                title={title}
                            />
                        ))}
                    </div>
                </CarouselItem>
            ))}
        </Carousel>
    );
};
