import { FC, WebCellProps } from 'web-cell';
import classNames from 'classnames';

import * as style from './index.module.less';

export interface SectionProps extends WebCellProps {
    center?: boolean;
    summary?: string;
}

export const Section: FC<SectionProps> = ({
    className,
    center,
    title,
    summary,
    children,
    ...rest
}) => (
    <section className={classNames('bg-white', 'pt-5', className)} {...rest}>
        <div className="narrow-box py-5">
            <h2
                className={classNames(
                    style.title,
                    center && style.center,
                    !summary && 'mb-4'
                )}
            >
                {title}
            </h2>
            {summary && (
                <p
                    className={classNames(
                        'text-muted',
                        'font-italic',
                        'small',
                        'mb-5',
                        center && 'text-center'
                    )}
                >
                    {summary}
                </p>
            )}
            {children}
        </div>
    </section>
);
