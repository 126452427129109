.card {
    width: 100%;
    height: 20rem;
    background-position: center;
    background-size: cover;
    border: 0.25rem solid white;
    & > a {
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        color: white !important;
        opacity: 0;
        transition: 0.25s;
        &:hover {
            opacity: 1;
        }
        & > * {
            margin: 1rem;
        }
        hr {
            display: block;
            margin-left: 0;
            border-top-color: white !important;
            width: 2rem;
        }
    }
}
@media (min-width: 577px) {
    .card {
        width: 20rem;
    }
}
@media (min-width: 769px) {
    .card {
        width: 33.33%;
    }
}
