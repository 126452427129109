import { FC, WebCellProps } from 'web-cell';
import { CardBody, CardImg, CardProps, Card, CardTitle } from 'boot-cell';

import * as style from './index.module.less';

export type ArticleCardProps = CardProps & WebCellProps<HTMLAnchorElement>;

export const ArticleCard: FC<ArticleCardProps> = ({
    image,
    href,
    title,
    children,
    ...rest
}) => (
    <Card {...rest} className={`shadow-sm mb-4 ${style.card}`}>
        <CardImg className={`object-fit-cover ${style.image}`} src={image} />
        <CardBody>
            <CardTitle>
                <a
                    className="stretched-link text-decoration-none text-dark"
                    href={href}
                >
                    {title}
                </a>
            </CardTitle>

            {children}
        </CardBody>
    </Card>
);
