import { HTTPClient } from 'koajax';
import { Filter, ListModel } from 'mobx-restful';
import { buildURLData } from 'web-utility';

const { hostname, protocol } = self.location;

export const service = new HTTPClient({
    baseURI: `${protocol}//${
        hostname === 'localhost' ? 'localhost:1337' : 'data.in235.com'
    }`,
    responseType: 'json'
});

export interface BaseData extends Record<'created_at' | 'updated_at', string> {
    id: number;
}

export abstract class StrapiListModel<
    D extends BaseData,
    F extends Filter<D> = Filter<D>
> extends ListModel<D, F> {
    client = service;

    searchKeys: string[] = [];
    rangeKeys: string[] = [];
    relations: string[] = [];

    makeCondition(key: string, value: any) {
        const { searchKeys, rangeKeys, relations } = this;

        return rangeKeys.includes(key) && value instanceof Array
            ? [
                  [`${key}_gte`, value[0]],
                  value[1] && [`${key}_lte`, value[1]]
              ].filter(Boolean)
            : [
                  relations.includes(key)
                      ? typeof value === 'number'
                          ? `${key}._id`
                          : `${key}.name`
                      : searchKeys.includes(key)
                        ? `${key}_contains`
                        : key,
                  value
              ];
    }

    async loadPage(page: number, size: number, filter: F) {
        const condition = Object.entries(filter)
            .map(([key, value]) => this.makeCondition(key, value))
            .flat();

        const { body } = await this.client.get<D[]>(
            `${this.baseURI}?${buildURLData([
                ...condition,
                ['_start', (page - 1) * size],
                ['_limit', size],
                ['_sort', 'updated_at:DESC']
            ])}`
        );
        const { body: count } = await this.client.get<number>(
            `${this.baseURI}/count?${buildURLData(condition)}`
        );
        return { pageData: body, totalCount: count };
    }
}

export interface File extends BaseData {
    url: string;
}
