import { FC } from 'web-cell';
import classNames from 'classnames';
import { CardProps } from 'boot-cell';

import * as style from './index.module.less';
import { service } from '../../model/Base';

export type ProfileCardProps = CardProps;

export const ProfileCard: FC<ProfileCardProps> = ({
    className,
    image,
    title,
    subtitle,
    children,
    ...rest
}) => (
    <div {...rest} className={classNames(style.box, className)}>
        <header className="h-50 d-flex flex-column align-items-center justify-content-around bg-primary">
            <img
                className={style.avatar}
                src={new URL(image, service.baseURI) + ''}
            />
            <h3 className="h5">{title}</h3>
            <p>{subtitle}</p>
        </header>
        <p className="p-3 text-muted">{children}</p>
    </div>
);
