.opacity {
    transition: 0.25s;
    opacity: 0;
}
.hover(@color) {
    &:hover,
    &.active {
        color: @color !important;
        &::before {
            opacity: 1;
        }
    }
}

.header {
    position: fixed;
    width: 100%;
    transition: 0.25s;
    &:not([open]) {
        &:not(:hover):not(.scrolled) {
            background: transparent !important;
            box-shadow: none !important;
        }
        @media (min-width: 768px) {
            nav {
                opacity: 0;
            }
            &:hover,
            &.scrolled {
                nav {
                    opacity: 1;
                }
            }
        }
    }
    :global {
        .navbar-brand {
            transition: 0.25s;
        }
        .navbar-nav {
            .nav-link {
                position: relative;
                transition: 0.25s;
                @media (max-width: 768px) {
                    &::before {
                        content: attr(data-title);
                        text-transform: uppercase;
                        color: var(--primary-color);
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        line-height: 4.5;
                        .opacity();
                    }
                    .hover(transparent);
                }
                @media (min-width: 768px) {
                    &::before {
                        content: '◆';
                        position: absolute;
                        bottom: -2rem;
                        left: 50%;
                        transform: translateX(-50%);
                        .opacity();
                    }
                    .hover(var(--primary-color));
                }
            }
            .dropdown-toggle:hover,
            .dropdown.active > .dropdown-toggle {
                color: var(--primary-color) !important;
            }
        }
        .navbar-toggler {
            border-color: transparent;
        }
        .navbar-toggler-icon {
            display: flex;
            align-items: center;
            background-image: none;
            font-family: bootstrap-icons !important;
            font-size: 2rem;
            &::before {
                content: '\f478';
            }
        }
        @media (max-width: 576px) {
            .navbar-collapse {
                text-align: center;
                .dropdown-menu {
                    text-align: center;
                }
                form {
                    margin: auto !important;
                }
            }
        }
    }
    &[theme='dark'] :global {
        .navbar-toggler-icon {
            color: white;
        }
    }
    &[open] :global {
        .navbar-brand {
            opacity: 0;
        }
        .navbar-toggler-icon::before {
            content: '×';
        }
    }
    img {
        width: 6.125rem;
        margin-right: 10rem;
    }
    form {
        margin: 0;
        width: 18.375rem;
        position: relative;

        input[type='search'] {
            border-radius: 1.25rem !important;
        }
        button {
            appearance: none;
            border: none;
            background: transparent;
            color: lightgray;
            position: absolute;
            top: 0.4rem;
            right: 0.4rem;
        }
    }
}
.toolbar {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
}
.footer {
    .brand {
        img {
            width: 50%;
            @media (min-width: 576px) {
                width: 100%;
            }
        }
        span {
            @media (min-width: 576px) {
                font-size: 1.25rem;
            }
            @media (min-width: 768px) {
                font-size: 2rem;
            }
        }
        & + ul :global .h3 {
            font-size: 18px;
            &::after {
                content: '';
                display: inline-block;
                vertical-align: middle;
                margin: auto 0.5rem;
                width: 2rem;
                border: 1px solid #ffe600;
            }
        }
    }
    .QRC {
        display: block;
        width: 5rem;
        margin: 0.5rem auto;
    }
}
