import { WebCellProps } from 'web-cell';
import classNames from 'classnames';
import { MediaObjectProps, FAIcon } from 'boot-cell';

import * as style from './index.module.less';
import { service } from '../../model/Base';

export interface StaffCardProps
    extends MediaObjectProps,
        Omit<WebCellProps, 'title'> {
    name: string;
}

export function StaffCard({
    className,
    imageColumn = 'end',
    image,
    name,
    title,
    children,
    ...rest
}: StaffCardProps) {
    const right = imageColumn !== 'end';

    return (
        <div
            className={classNames(
                style.box,
                right && 'flex-row-reverse',
                className
            )}
            {...rest}
        >
            {typeof image === 'string' ? (
                <img src={new URL(image, service.baseURI) + ''} />
            ) : (
                image
            )}
            <div className="flex-fill">
                <header
                    className={classNames(
                        'd-flex',
                        right && 'flex-row-reverse'
                    )}
                >
                    <span className={style.icon}>
                        <FAIcon
                            color="dark"
                            size="lg"
                            name={`quote-${imageColumn}`}
                        />
                    </span>
                    <div
                        className={classNames(
                            'd-inline-block',
                            'mx-2',
                            'text-white',
                            right && 'text-right'
                        )}
                    >
                        <h3 className="h5 mb-1">{name}</h3>
                        <small>{title}</small>
                    </div>
                </header>
                <p className="small text-muted">{children}</p>
            </div>
        </div>
    );
}
