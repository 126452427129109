import { Filter, ListModel, Stream } from 'mobx-restful';
import { mergeStream } from 'web-utility';

import { MetaModel } from './Meta';
import { ArticleModel, BaseArticle } from './Article';
import { CaseModel } from './Case';
import { ActivityModel } from './Activity';
import { FeedbackModel } from './Feedback';
import { OrderModel } from './Order';

export * from './Base';
export * from './Meta';
export * from './Article';
export * from './Case';
export * from './Activity';
export * from './Feedback';
export * from './Order';

export const meta = new MetaModel();
export const article = new ArticleModel();
export const showCase = new CaseModel();
export const activity = new ActivityModel();
export const feedback = new FeedbackModel();
export const order = new OrderModel();

export class SearchModel extends Stream<BaseArticle>(ListModel) {
    openStream(filter: Filter<BaseArticle>) {
        return mergeStream(
            async function* () {
                for (let i = 1; !article.noMore; i++)
                    yield* await article.getList(filter, i);
            },
            async function* () {
                for (let i = 1; !showCase.noMore; i++)
                    yield* await showCase.getList(filter, i);
            }
        );
    }
}

export const search = new SearchModel();
