.box {
    display: flex;
    width: 60%;
    margin: 2rem auto;
    & > img {
        width: 8rem;
        height: 8rem;
        object-fit: cover;
    }
    .icon {
        width: 3rem;
        height: 3rem;
        background: var(--primary-color);
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    p {
        height: 5rem;
        background: white;
        padding: 0.75rem 1rem;
        line-height: 2.4;
        word-break: break-all;
        overflow: hidden;
    }
}
