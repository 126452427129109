.root article {
    figure {
        figcaption {
            text-align: center;
            margin-top: 1rem;
        }
        img {
            display: block;
            margin: auto;
            max-width: 100%;
        }
    }
    iframe:not(.embed-responsive-item) {
        width: 100%;
        height: 70vh;
    }
}
