import { FC, WebCellProps } from 'web-cell';
import classNames from 'classnames';

import { Icon } from './Icon';

export interface AddressProps extends WebCellProps {
    icon: string;
}

export const Address: FC<AddressProps> = ({
    className,
    icon,
    children,
    ...rest
}) => (
    <div
        className={classNames('d-flex', 'justify-content-center', className)}
        {...rest}
    >
        <Icon
            className="d-none d-sm-inline-block mr-3"
            color="primary"
            size={2}
            name={icon}
        />
        <p className="text-left" style={{ lineHeight: '2.5' }}>
            {children}
        </p>
    </div>
);
