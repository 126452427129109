import {
    WebCellProps,
    component,
    WebCell,
    attribute,
    reaction,
    observer
} from 'web-cell';
import { observable } from 'mobx';
import { Image, Carousel, CarouselItem, SpinnerBox } from 'boot-cell';

import { Section, BrandCard, TabNav, MoreButton } from '../../component';
import * as style from './index.module.less';
import { service as services } from '../Order/data';
import { meta, showCase, service } from '../../model';

export type HomePageProps = WebCellProps &
    Record<'section' | 'category', string>;

export interface HomePage extends WebCell<HomePageProps> {}

@observer
@component({
    tagName: 'home-page'
})
export class HomePage extends HTMLElement implements WebCell<HomePageProps> {
    @attribute
    @observable
    accessor section = '';

    @reaction(_this => _this.section)
    handleScroll(section: string) {
        this.querySelector('#' + section)?.scrollIntoView({
            behavior: 'smooth'
        });
    }

    @attribute
    @observable
    accessor category = '';

    @reaction(_this => _this.category)
    reloadData() {
        this.loadList(1);
    }

    loadList(page?: number) {
        return showCase.getList({ category: this.category }, page);
    }

    connectedCallback() {
        meta.getGallery();
        this.loadList(1);

        if (meta.deviceType === 'phone') meta.getPartners();
    }

    serviceHover = ({ target }: MouseEvent) => {
        const image = (target as HTMLElement).querySelector('img')!;
        const {
            src,
            dataset: { hoverImage }
        } = image;

        image.src = hoverImage;
        image.dataset.hoverImage = src;
    };

    renderService = ({
        icon,
        icon_active,
        name,
        sub
    }: (typeof services)[0]) => (
        <li
            className="list-inline-item m-0 p-1 p-sm-3 col-6 col-md-4"
            onMouseEnter={this.serviceHover}
            onMouseLeave={this.serviceHover}
        >
            <div className={style.service}>
                <img src={icon} data-hover-image={icon_active} />
                <h3 className="h6 mt-3 mb-4">{name}</h3>
                <ul className="list-inline small text-muted">
                    {sub?.map(name =>
                        name !== '其它' ? (
                            <li className="list-inline-item">{name}</li>
                        ) : null
                    )}
                </ul>
            </div>
        </li>
    );

    render() {
        const { category } = this,
            categories = meta.categoriesOf('Case'),
            { gallery } = meta,
            { downloading, currentPage } = showCase;

        return (
            <>
                {gallery[0] && (
                    <Carousel controls interval={gallery[1] ? 3 : 0}>
                        {gallery.map(({ url }) => (
                            <CarouselItem>
                                <Image
                                    className="w-100 h-50 object-fit-cover"
                                    src={new URL(url, service.baseURI) + ''}
                                />
                            </CarouselItem>
                        ))}
                    </Carousel>
                )}
                <Section
                    id="business"
                    center
                    title="Core Business"
                    summary="以策略、创意、设计、服务为核心的文化传播广告公司"
                >
                    <ul className="list-inline row m-0">
                        {services
                            .filter(({ sub }) => sub)
                            .slice(0, -1)
                            .map(this.renderService)}
                    </ul>
                </Section>

                <section className="bg-white">
                    <SpinnerBox
                        className="container p-0"
                        cover={downloading > 0}
                    >
                        <TabNav
                            className="m-0"
                            header="合作案例"
                            path="?category="
                            list={['All', ...categories].map(title => ({
                                title
                            }))}
                            activeIndex={categories.indexOf(category) + 1}
                        />
                        <ul className="list-unstyled p-0 d-flex justify-content-center justify-content-md-start flex-wrap">
                            {currentPage.map(
                                ({ banner, client, id, title, tags }) => (
                                    <BrandCard
                                        image={banner?.url || client.logo.url}
                                        href={'case?id=' + id}
                                        title={title}
                                        description={tags
                                            .map(({ name }) => name)
                                            .join('/')}
                                    />
                                )
                            )}
                        </ul>
                        <MoreButton href="cases" title="合作案例" />
                    </SpinnerBox>
                </section>
            </>
        );
    }
}
