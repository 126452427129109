import { BaseData, StrapiListModel } from './Base';

export type Order = BaseData &
    Record<
        | 'company'
        | 'service_0'
        | 'service_1'
        | 'industry'
        | 'requirement'
        | 'client_type'
        | 'contact'
        | 'phone'
        | 'email',
        string
    >;
export class OrderModel extends StrapiListModel<Order> {
    baseURI = '/orders';
}
