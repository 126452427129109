import { BaseData, File } from './Base';
import { BaseArticle, ArticleModel } from './Article';

export interface Client extends BaseData, Record<'name' | 'brand', string> {
    logo: File;
}

export interface Case extends BaseArticle {
    client: Client;
}

export class CaseModel extends ArticleModel<Case> {
    baseURI = '/cases';
    accessor pageSize = 9;
}
