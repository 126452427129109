import { FC } from 'web-cell';
import classNames from 'classnames';
import { ButtonProps, Button } from 'boot-cell';

export const MoreButton: FC<ButtonProps> = ({
    className,
    children,
    ...rest
}) => (
    <Button
        className={classNames(
            'w-25',
            'mx-auto',
            'my-5',
            'bg-transparent',
            className
        )}
        block
        outline
        color="secondary"
        {...rest}
    >
        {children[0] ? children : 'more →'}
    </Button>
);
